import React from 'react';
import { Link, navigate } from '@reach/router';
import PropTypes from 'prop-types';

import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';
import { routes } from '../../services/navigation/navigation.service.routes';
import { env } from '../../env';

import { logoMapping } from '../../services/logo-mapping/LogoMapping.js'; // Import the mapping
import { PROPERTY_ID, whiteLabelPropertyID } from '../RenderConditionalComponent/RenderConditionalComponent';

import './ApplicationLogo.scss';

const ApplicationLogo = ({ notLink }) => {
  const title = `${env.REACT_APP_SITE_TITLE} Home`;

  const onClick = () => {
    navigate(routes.root);
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }
  };

  // Determine the correct LogoWeb and LogoMobile based on whiteLabelPropertyID
  const { LogoWeb, LogoMobile } = logoMapping[whiteLabelPropertyID] || logoMapping[PROPERTY_ID.nakedSword];

  const Elem = notLink ? 'span' : Link;
  const props = {
    className: 'ApplicationLogo',
    title: title,
  };

  if (!notLink) {
    props.onClick = onClick;
    props.to = routes.root;
  }

  return (
    <Elem {...props}>
      <LogoWeb className="DesktopIcon" />
      <LogoMobile className="MobileIcon" />
    </Elem>
  );
};

ApplicationLogo.propTypes = {
  notLink: PropTypes.bool,
};

export default ApplicationLogo;
