import React, { useRef } from 'react';

import BaseSectionHeader from '../../../../components/BaseSectionHeader/BaseSectionHeader';
import SlickSlider from '../../../../components/SlickSlider/SlickSlider';
import BannerSetViewFunc from '../../../../components/BannerSetView/BannerSetViewFunc';

import useBanners from '../../../../hook/useBanners';
import useInViewport from '../../../../hook/useInViewport';

import './FeaturedRelease.scss';

const FeaturedRelease = () => {
  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);
  const featuredReleases = useBanners('featuredReleases', isVisible);

  const renderSlider = () => <SlickSlider slides={featuredReleases} />;

  return (
    <div className="FeaturedRelease" ref={containerRef}>
      <div className="FeaturedRelease-inner">
        <BaseSectionHeader primaryText="Featured release" />
        {renderSlider()}
        <div className="DuoBannerSet">
          <BannerSetViewFunc bannerName="featuredReleasesLeft" width={window.innerWidth / 1.5} />
          <BannerSetViewFunc bannerName="featuredReleasesRight" width={window.innerWidth / 1.5} />
        </div>
      </div>
    </div>
  );
};

export default FeaturedRelease;