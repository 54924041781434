import React, { useState, useCallback, useEffect, useRef,  lazy } from 'react';
import { globalHistory, Location, Redirect, Router } from '@reach/router';
import { withTranslation } from 'react-i18next';
//import { useTrackedJoinLink } from '../../hook/useTrackedJoinLink';
import MainDashboardHeader from './MainDashboardHeader/MainDashboardHeader';
import SideMenu from '../../components/mobile/SideMenu/SideMenu';
import { UsersController } from '../../controllers/users-controller/users.controller';
import { singleSignOff } from '../../services/token-service/token.service';
//import { NatsContext } from '../../context/NatsContext';


import MainDashboardView from './MainDashboardView/MainDashboardView';

import {
  getFavoritesPageRoute,
  //getJoinLinkRoute,
  getSignInPageRoute,
  getMostWatchedPageRoute,
  getOriginalsScenesPageRoute,
  routes,
} from '../../services/navigation/navigation.service.routes';
import { getWhiteLabelConfig } from '../../services/properties/properties.service';
import { logError } from '../../services/log/log.service';
import { getPlayerConfig } from '../../services/player/player.service';
import { isWeb, setContentScrolltop } from '../../services/util-service/util.service';

import { DashboardController } from '../../controllers/dashboard-controller/DashboardController';
import { MainDashboardController } from './MainDashboardController';
import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';

import './MainDashboard.scss';
import '../../styles/livechat_buttons.scss';
import throttle from 'lodash/throttle';
//import { getUserNATSCode, setUserNATSCode } from '../../services/nats-service/nats.service';
//import { fetchNatsCode, getNatsUserData } from '../../services/nats-service/nats.service';
import { getURLPath } from '../../services/navigation/navigation.service';
import { isUserLoggedIn } from '../../services/token-service/token.service';
import MobileSecondaryNavigation from '../../components/mobile/MobileSecondaryNavigation/MobileSecondaryNavigation';
//import { env } from '../../env';

//import { createJoinLinkFromLastCode } from '../../links';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import DirectorPage from './DirectorPage/DirectorPage';
import BannerSetViewFunc from '../../components/BannerSetView/BannerSetViewFunc.jsx';
import { LoadingText } from '../../components/LoadingMask/LoadingMask.js';
import AvsPage from '../AvsPage/AvsPage.js';
import StreamTest from '../StreamTest/StreamTest.js';
import AgeWarningOverlay from '../../components/AgeWarningOverlay/AgeWarningOverlay.js';
import Footer from '../../components/Footer/Footer.js';

//import { useTrackedJoinLink } from '../../hook/useTrackedJoinLink.js';

import JoinHandler from '../../components/JoinHandler/JoinHandler.js';


//import { canUserEngagementEventStart } from '../../components/Analytics/CanUserEngagementEventStart.js';

//import { incrementPageViews } from '../../components/Analytics/UserEngagementEvent.js';
//import trackPageView from '../../components/Analytics/trackPageView.js';


// Initialize controllers
// Access NATS context values

const JustAdded = lazy(() => import('./JustAddedPage/JustAddedPage'));
const JustAddedPage = lazy(() => import('./JustAddedPage/JustAddedPage'));
// Lazy-loaded JustAddedPage
//const JustAddedPage = lazy(() => import('./JustAddedPage/JustAddedPage'));
//const JustAddedComponent = lazy(() => import('./JustAddedComponent/JustAddedComponent'));
const SearchResultsPage = lazy(() => import('./SearchResultsPage/SearchResultsPage'));
const MyAccountPage = lazy(() => import('./MyAccountPage/MyAccountPage'));
const MemberDeals = lazy(() => import('./MemberDealsPage/MemberDealsPage'));
//const MyDealsPage = lazy(() => import("./MyAccountPage/MyDeals/MyDeals"));
const CompliancePage = lazy(() => import('./CompliancePage/CompliancePage'));
const TopTen = lazy(() => import('./TopTen/TopTen'));
const OriginalsPage = lazy(() => import('./OriginalsPage/OriginalsPage'));
const ThemeDetailsPage = lazy(() => import('./ThemesPage/ThemeDetailsPage/ThemeDetailsPage'));
const MovieDetailsPage = lazy(() => import('./MovieDetailsPage/MovieDetailsPage'));
const StudioDetailsPage = lazy(() => import('./StudiosPage/StudioDetailsPage/StudioDetailsPage'));
const SeriesPage = lazy(() => import('./SeriesPage/SeriesPage'));

const StarsPage = lazy(() => import('./StarsPage/StarsPage'));
const StudiosPage = lazy(() => import('./StudiosPage/StudiosPage'));
const ThemesPage = lazy(() => import('./ThemesPage/ThemesPage'));
const PlaylistsPage = lazy(() => import('./PlaylistsPage/PlaylistsPage'));
const NewsLetterPage = lazy(() => import('./NewsLetterPage/NewsLetterPage'));
const NewsletterConfirmPage = lazy(() => import('../../components/NewsletterSignUp/NewsletterConfirm.js'));
const HelpPage = lazy(() => import('./HelpPage/HelpPage'));
const TermsOfUsePage = lazy(() => import('./TermsOfUsePage/TermsOfUsePage'));
const PrivacyPolicyPage = lazy(() => import('./PrivacyPolicyPage/PrivacyPolicyPage'));
const StreamBlocked = lazy(() => import('./StreamBlockedPage/StreamBlocked'));
const ForgotPasswordPage = lazy(() => import('./ForgotPasswordPage/index.js'));
const Streamate = lazy(() => import('./Streamate/Streamate.js'));
const Live = lazy(() => import('./Live/Live.js'));
const WeekendsPage = lazy(() => import('./WeekendsPage/WeekendsPage'));
const SpecialOffers = lazy(() => import('../SpecialOffers/SpecialOffers'));
const Free = lazy(() => import('./SpecialPromos/Free/Free'));
const FavoritesPage = lazy(() => import('./FavoritesPage/FavoritesPage'));
const UserPlaylistsPage = lazy(() => import('./UserPlaylistsPage/UserPlaylistsPage'));
const SignInPage = lazy(() => import('./SignInPage/SignInPage'));
const ViewingHistoryPage = lazy(() => import('./ViewingHistoryPage/ViewingHistoryPage'));
const NotFound = lazy(() => import('../NotFound/NotFound'));
const ModalContainer = lazy(() => import('../../components/ModalContainer/ModalContainer'));
const Notifications = lazy(() => import('../../components/Notifications/Notifications'));
const MobileUserActions = lazy(() => import('../../components/mobile/MobileUserActions/MobileUserActions'));

export const frontPosterSizes = {
  // updated on resize
  grid: window.innerWidth / 3,
  details: window.innerWidth,
};

const MainDashboard = ({ t, ...props }) => {
  //const { fetchNatsCode, decodedNatsCodeData } = useContext(NatsContext);

  //const { joinLink, isLoading } = useTrackedJoinLink();

  // State variables
  console.log('maindashboard started');
  const [loading, setLoading] = useState(true);
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
 // const [whitelabelNatsCode, setWhitelabelNatsCode] = useState(undefined);
  const [, setUserStateChange] = useState(undefined);
  const [searchDropdownValue, setSearchDropdownValue] = useState('');


 // const { joinLink } = useTrackedJoinLink();

  //const toggleMobileMenu = () => {
   // setSideMenuVisible((prev) => !prev);
 // };



  const handleSearchDropdownValue = (value) => {
    setSearchDropdownValue(value);
  };

  const onUserStateChange = useCallback(() => {
    setUserStateChange(new Date().getTime());
  }, []);

  useEffect(() => {
    const subscription = UsersController.userStateChange.subscribe(onUserStateChange);
    singleSignOff(onUserStateChange, 'add'); // Add listener

    return () => {
      subscription.unsubscribe(); // Cleanup subscription
      singleSignOff(onUserStateChange, 'remove'); // Remove listener
    };
  }, [onUserStateChange]);




  // Refs
  const locationPathnameRef = useRef(null);


  const toggleMobileMenu = useCallback((closeSignupDialog = true) => {
    if (SignInController.isSignupDisplayed() && closeSignupDialog) {
      SignInController.closeSignupDialog();
    }
    setSideMenuVisible((prev) => !prev);
  }, []);

  const handleMainContainerClick = useCallback(() => {
    if (sideMenuVisible) {
      setSideMenuVisible(false); // Close the menu by setting visibility to false
    }
    MainDashboardController.notifyMainDashboardClick(); // Notify the dashboard
  }, [sideMenuVisible]);

  const onRequestFailure = useCallback((error) => {
    logError(error);
  }, []);

  const onResize = useCallback(() => {
    const width = Math.round(window.innerWidth);
    frontPosterSizes.grid = Math.round(width / 3);
    frontPosterSizes.details = width;
  }, []);

  const throttledTouchStart = useRef();

  useEffect(() => {
    throttledTouchStart.current = throttle((event) => {
      console.log('Touch start event:', event);
      // Handle touch start logic here
    }, 1000);

    return () => {
      throttledTouchStart.current.cancel();
    };
  }, []);


  // Render Just Added Component
  const renderJustAdded = ({ pathname }) => {
    const isHomePage = pathname === routes.root;
    const isVisible =
      isHomePage || ((pathname.includes(routes.justAdded) || pathname.includes(routes.mostWatched)) && isWeb());
    return isVisible ? (
      <JustAdded showFilters={isUserLoggedIn() && isHomePage && isWeb()} />
    ) : null;
  };

  const routeChangeListener = useCallback(({ action, location }) => {
    const { pathname } = location;

    if (!pathname.includes('http')) {
      setUserStateChange(action);
    }

    if (isWeb()) {
      if (locationPathnameRef.current !== location.pathname) {
        setContentScrolltop();
      }
      locationPathnameRef.current = location.pathname;
    } else if (locationPathnameRef.current !== location.href) {
      setContentScrolltop();
      locationPathnameRef.current = location.href;
    }
  }, []);

  useEffect(() => {
    const unsubscribe = globalHistory.listen(routeChangeListener);

    const promises = [
      getWhiteLabelConfig().catch(onRequestFailure),
      getPlayerConfig().catch(onRequestFailure),
    ];

    Promise.all(promises)
      .then(([_, playerConfigResponse]) => {
        const { propertyPlayerConfig = {} } = playerConfigResponse?.data?.data || {};
        DashboardController.setPlayerConfig(propertyPlayerConfig);

        console.log('loading should be set false here');
        setLoading(false); // Ensure loading is turned off
      })
      .catch(onRequestFailure);

    singleSignOff(onUserStateChange, 'add');
    window.addEventListener('resize', onResize);

    return () => {
      unsubscribe();
      singleSignOff(onUserStateChange, 'remove');
      window.removeEventListener('resize', onResize);
    };
  }, [onRequestFailure, routeChangeListener, onUserStateChange, onResize]);


  const renderMemberUpsellBottomBanner = () => {
    const urlPath = getURLPath(); // Assume this is imported
    return (urlPath.includes(routes.myAccount) || urlPath.includes(routes.help)) &&
    isWeb() &&
    isUserLoggedIn() ? (
      <BannerSetViewFunc bannerName="memberUpsellBottom" />
    ) : null;
  };


  const renderSpecialPages = () => {
    const pathname = window.location.pathname;

    if (pathname.includes(`/${routes.ageVerificationLanding}`)) {
      console.log('Navigating to AVS Page');
      return <AvsPage />;
    }

    if (pathname === `/${routes.diag}`) {
      console.log('Navigating to Stream Test Page');
      return <StreamTest />;
    }

    return null; // Render nothing if no special page matched
  };


  //useEffect(() => {
  // configureNATSToken();
  // }, [configureNATSToken]);

  // Render functions
 // const renderSideMenu = () => {
  //  if (isWeb()) return null;
  //  const className = sideMenuVisible ? 'Visible' : '';
  //  return <SideMenu className={className} onItemClick={toggleMobileMenu} />;
//   };

  // Handle sign-in dialog with the exact misspelling
  const isSignuoFormOpen = () => {
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }
  };


  return (
    <ErrorBoundary>
      {!loading ? ( // Check loading state
        <div className="MainDashboard" onClick={handleMainContainerClick}>
          <AgeWarningOverlay />
          <MainDashboardHeader
            path={`${routes.root}/*`}
            toggleMobileMenu={toggleMobileMenu}
            handleSearchDropdownValue={handleSearchDropdownValue}
          />
          {renderSpecialPages() || ( // Render AVS or diagnostic pages if applicable
            <div className="MainContent" onTouchStart={(event) => throttledTouchStart.current(event)}
                 onScroll={isSignuoFormOpen} // Preserving misspelled method name
            >
                <div className="MainContent-inner">
                  {/* Render SideMenu based on visibility */}
                  {sideMenuVisible && (
                    <SideMenu
                      className="Visible"
                      onItemClick={() => setSideMenuVisible(false)} // Close menu on item click
                    />
                  )}
                <div className="MainContainer">
                  <Location>
                    {({ location }) => (
                      <>
                        {renderJustAdded(location)}

                        <Router primary={false} location={location}>
                          <MainDashboardView path={routes.root} />
                         <CompliancePage path={routes.compliance} />
                          <DirectorPage path={routes.directorInfo} />
                          <FavoritesPage path={getFavoritesPageRoute()} />
                          <HelpPage path={routes.help} />

                          {/*  <JoinHandler path={routes.join} /> */}

                          <JustAddedPage path={routes.mostWatched} />
                          <UserPlaylistsPage path={routes.userPlaylists} />
                          <SignInPage path={getSignInPageRoute()} />
                          <MemberDeals path={routes.memberDeals} />
                          <MovieDetailsPage path={routes.movieScenes} />
                          <MovieDetailsPage path={routes.movieInfo} />

                          <MyAccountPage path={routes.myAccount} />
                          <NewsLetterPage path={routes.newsletter} />
                          <NewsletterConfirmPage path={routes.newsletterconfirm} />
                          <OriginalsPage path={routes.originals} />
                          <PlaylistsPage path={routes.playlistsAnything} />



                          <StudiosPage path={routes.studios} />
                          <StudioDetailsPage path={routes.studiosInfo} />
                          <TermsOfUsePage path={routes.termsOfUse} />
                          <PrivacyPolicyPage path={routes.privacyPolicy} />
                          <StarsPage path={routes.starsAnything} />
                          <TopTen path={routes.top10} />
                          <SeriesPage path={routes.seriesInfo} />
                          <ThemesPage path={routes.themes} />
                          <ThemeDetailsPage path={routes.themeInfo} />
                          <ThemeDetailsPage path={routes.sexActInfo} />
                          <ViewingHistoryPage path={routes.viewingHistory} />
                          <SpecialOffers path={routes.specialOffers} />
                          <Free path={routes.free} />
                          <Streamate path={routes.streamate} />
                          <Live path={routes.live} />
                          <StreamBlocked path={routes.streamblocked} />
                          <ForgotPasswordPage path={routes.forgotpasswordpage} />
                          <WeekendsPage path={routes.weekends} />
                          <SearchResultsPage path={routes.search} searchDropdownValue={searchDropdownValue} />
                          <Redirect to={getMostWatchedPageRoute()} from={`/most-watched`} noThrow />
                          <Redirect to={getOriginalsScenesPageRoute(1, 'Newest')} from={routes.originals} noThrow />
                          <NotFound
                            default
                            linkWrapperClass="Exclamation"
                            iconClass="fas fa-exclamation"
                            title={t('MainDashboard.notFoundTitle')}
                            subtitleRowOne={t('MainDashboard.notFoundRowOne')}
                          />
                        </Router>
                      <Footer />
                        {renderMemberUpsellBottomBanner()}
                        {renderSecondaryMobileNavigation()}
                        {renderMobileUserActions()}
                        <ModalContainer />
                        <Notifications />
                      </>
                    )}
                  </Location>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <LoadingText /> // Display LoadingText when loading
      )}
    </ErrorBoundary>
  );
}
const renderSecondaryMobileNavigation = () => {
  const pathname = window.location.pathname;
  const path = `/${pathname.split('/')[1]}`;

  const allowedPages = [
    routes.root,
    routes.theme,
    routes.justAdded,
    routes.mostWatched,
    routes.originals,
    routes.trentonducatioriginals,
    routes.stars,
    routes.studios,
    routes.search,
    routes.series,
    routes.director,
  ];

  const allowedPagesWithSlash = allowedPages.map((route) => {
    if (route === '/') return null;
    return route.startsWith('/') ? route : `/${route}`;
  });

  if (allowedPagesWithSlash.includes(path)) {
    return <MobileSecondaryNavigation />;
  }

  return null;
};

const renderMobileUserActions = () => {
  if (isWeb()) return null;
  return <MobileUserActions />;
};

MainDashboard.displayName = 'MainDashboard';

export default withTranslation()(MainDashboard);