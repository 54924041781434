import React, { useEffect, Suspense, useRef } from 'react';
import { Helmet } from 'react-helmet';
import '@fortawesome/fontawesome-free/css/all.min.css';

import LoadingMask, { LoadingText } from './components/LoadingMask/LoadingMask';
import MainDashboard from './pages/MainDashBoard/MainDashboard';
import { MiniPlayerContainer } from '@falconstudios/ns-player';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import { NatsProvider } from './context/NatsContext';

import Div100vh from 'react-div-100vh';
import { handleTokenRefresh } from './services/token-service/token.service';
import { env } from './env';
import './styles/main.scss';
import './App.scss';
import './overrides/overrides.css';
import ImagesLightBoxStyle from './components/ImagesLighbox/ImagesLightBoxStyle';
import { HtmlContentContextProvider } from './context/HtmlContentContext';

function App() {
  const wrapperRef = useRef();

  useEffect(() => {
    // Handle token refresh on app load
    handleTokenRefresh();

    // Optional: Additional logic using wrapperRef if needed
    if (wrapperRef.current) {
      console.log("Wrapper div ref is accessible");
    }
  }, []);

  const reactappsitename = env.REACT_APP_SITE_NAME.replace(/\./g, '');

  return (
    <div className={`${reactappsitename}`}>
      <Div100vh>
        <div className="App">
          <Helmet>
            <title>{env.REACT_APP_SITE_TITLE}</title>
            <meta name="description" content={env.REACT_APP_SITE_DESCRIPTION} />
          </Helmet>
          <Suspense fallback={<LoadingText />}>
            <NatsProvider>
              <HtmlContentContextProvider>
                <MainDashboard contentRef={wrapperRef} />
              </HtmlContentContextProvider>
            </NatsProvider>
          </Suspense>
          <MiniPlayerContainer />
          <LoadingMask />
          <ImagesLightBoxStyle />
        </div>
      </Div100vh>
    </div>
  );
}

export default App;