
// services/nats.service.js

// Helper function to simulate a delay
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Default NATS codes based on hostname - if natsCode is null only
const getDefaultNatsCodeForHostname = (hostname) => {
  const appendableDefaultNatsCodes = {
    'localhost': 'MTA2NTEzLjU1LjIyLjEyOS4wLjAuMC4wLjA',
    'www.guerillaporn.com': 'MTA2NjIwLjU4LjIyLjEzMC4wLjAuMC4wLjA',
    'guerillaporn.com': 'MTA2NjIwLjU4LjIyLjEzMC4wLjAuMC4wLjA',
    'www.shawns-machine.com': 'MTA2NjIwLjU4LjIyLjEzMC4wLjAuMC4wLjA',
    'shawns-machine.com': 'MTA2NjIwLjU4LjIyLjEzMC4wLjAuMC4wLjA',
    'www.huntformen.com': 'MTA0NjQ0LjMxLjguMjUuMC4wLjAuMC4w',
    'www.mrman-trailertrashboys.com': '',
    'www.nakedsword.com': 'MTA1NDY3LjguMS4xLjAuMC4wLjAuMA',
    'www.nakedsword-mrman.com': 'MC4wLjIwLjkyLjAuMC4wLjAuMA',
    'www.nakedswordxxx.com': 'MTAwMTE2Ny4zMS4xMy44MjEuMC4wLjAuMC4w',
    'www.peterfever-nakedsword.com': 'NTQwMjU4My4wLjE0OC4yNzQuMC4wLjAuMC4w',
    'www.qreel.com': 'MTA1OTMwLjQ2LjE5Ljg3LjAuMC4wLjAuMA',
    'www.timvideovault.com': 'MC4wLjI2LjEyOC4wLjAuMC4wLjA',
    'www.trailertrashboys.com': 'MTA2NjMyLjYxLjMwLjE0OC4wLjAuMC4wLjA',
    'www.trentonducati.com': 'MTA1NDcwLjQzLjE2LjY0LjAuMC4wLjAuMA',
  };

  const fallbackNatsCode = appendableDefaultNatsCodes[hostname];
  if (fallbackNatsCode) {
    return `${fallbackNatsCode}-default-appended`;
  }

  return null;
};

// Retry logic to pull `nats_code` from localStorage
export const fetchNatsCode = async (maxRetries = 5, delayMs = 500) => {
  let attempts = 0;

  while (attempts < maxRetries) {
    const natsCode = localStorage.getItem('nats_code');
    if (natsCode && natsCode !== 'null') {
      console.log('Using NATS code from localStorage:', natsCode);
      return natsCode;
    }

    console.warn(`Attempt ${attempts + 1}: NATS code not found. Retrying...`);
    attempts += 1;
    await delay(delayMs);
  }

  // Fallback to default NATS code
  const hostname = window.location.hostname;
  const fallbackNatsCode = getDefaultNatsCodeForHostname(hostname);
  if (fallbackNatsCode) {
    console.warn(`Using fallback NATS code for hostname ${hostname}: ${fallbackNatsCode}`);
    localStorage.setItem('nats_code', fallbackNatsCode); // Store fallback for future use
    return fallbackNatsCode;
  }

  console.error('Failed to retrieve NATS code after maximum retries.');
  return null;
};

// Decode `nats_code` into structured data
export const decodedNatsCodeData = (data) => {
  const labels = [
    'AffiliateID',
    'ProgramID',
    'SiteID',
    'TourID',
    'CampaignID',
    'AdtoolID',
    'AdToolSub1ID',
    'AdToolSub2ID'
  ];

  if (!data) {
    console.warn('No NATS code provided. Returning null default values.');
    return labels.reduce((acc, label) => {
      acc[`nats_${label}`] = 'natscodenull';
      return acc;
    }, { natsCode: 'nullNATSstring', AppendedDefault: null });
  }

  let decodedData;
  let isDefaultCode = false;
  let fallbackCode = null;

  if (data.endsWith('-default-appended')) {
    isDefaultCode = true;
    fallbackCode = data.replace('-default-appended', ''); // Clean the code
    data = fallbackCode; // Use cleaned code for decoding
    console.log(`Decoding a default-appended NATS code. Original: ${fallbackCode}`);
  }

  try {
    const chars = data.length;
    const add = 4 - (chars % 4);
    if (add < 4) {
      data += '='.repeat(add);
    }

    decodedData = atob(data);
  } catch (error) {
    console.error('Error decoding NATS code:', error);
    return labels.reduce((acc, label) => {
      acc[`nats_${label}`] = 'natscodecorrpupted';
      return acc;
    }, { natsCode: `${data}corrupted`, AppendedDefault: fallbackCode });
  }

  const splitData = decodedData.split('.');
  const decodedValues = splitData.reduce((acc, value, index) => {
    acc[`nats_${labels[index]}`] = value || 'natscodecorrpupted';
    return acc;
  }, {});

  // Add metadata for default codes
  decodedValues.natsCode = data; // Store the clean code
  decodedValues.AppendedDefault = isDefaultCode ? fallbackCode : null; // Track fallback usage

  return decodedValues;
};

// Fetch user-related data from `fe-user-data` in localStorage
export const getNatsUserData = () => {
  const userData = localStorage.getItem('fe-user-data');

  if (userData) {
    try {
      return JSON.parse(userData) || {}; // Parse and return user data
    } catch (error) {
      console.error('Error parsing `fe-user-data`:', error);
    }
  }
  return {}; // Return an empty object if data is missing or corrupted
};

/*
export const setUserNATSCode = (nats) => {
  localStorage.setItem('nats_code', nats);
};



ORIGINAL
export const setUserNATSCode = (nats) => {
  localStorage.setItem('nats_code', nats);
};

export const getUserNATSCode = () => {
  return localStorage.getItem('nats_code') || undefined;
};

export const getNatsUserData = () => {
  const userData = localStorage.getItem('fe-user-data');

  if (userData) {
    try {
      const userObj = JSON.parse(userData) || {};
      return userObj;
    } catch (e) {
      console.error('Error parsing user data:', e);
    }
  }
  return {};
};
*/



// 2x check that this is not what is actually getting nats code into local storage - and why it takes time.
// fe-user-data for more user info (memberid)



// 7. Create a join link using the last known NATS code
/*
export const createJoinLinkFromLastCode = (lastCode) => {
const baseUrl = process.env.REACT_APP_JOIN_NOW_URL || ''; // Site-specific join URL
const defaultNatsCode = process.env.REACT_APP_DEFAULT_NATS_CODE; // Default NATS code from admin

const natsCode = lastCode || defaultNatsCode; // Use last code or fallback to default
if (!natsCode) {
console.error("No NATS code available for join link.");
return null; // Handle edge case
}

return `${baseUrl}/track/${natsCode}`; // Construct the join link
};

import axios from 'axios';
// Placeholder for API call when backend is ready
const response = await axios.get('/api/get-nats-code.php', { withCredentials: true });
natsCode = response.data?.natsCode;

if (natsCode) {
  console.log('Fetched NATS code from API:', natsCode);
  localStorage.setItem('nats_code', natsCode); // Cache the result in localStorage
} else {


  console.warn('No NATS code returned by the API.');
}
*/