import React from 'react';
import PropTypes from 'prop-types';
import Arrow from '../Arrow/Arrow';
import './BrowseNavigation.scss';

const BrowseNavigation = (props) => {
  const { className, baseText, children = [] } = props;

  if (children.length === 0) return null;

  return (
    <div className="BrowseNavigation">
      <div className={className}>
        <div className="BrowseNavigationText">{baseText}</div>
        <Arrow />
      </div>
      <div className="dropdown-content">{children}</div>
    </div>
  );
};

export default BrowseNavigation;

BrowseNavigation.propTypes = {
  className: PropTypes.string,
  baseText: PropTypes.string,
};