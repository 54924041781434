  // logo-mapping.js
  import { PROPERTY_ID } from '../../components/RenderConditionalComponent/RenderConditionalComponent';

  // Import all logo variations
  import { ReactComponent as LogoWeb_guerillaPorn } from '../../images/guerillaporn-nats-page-logo-with-text.svg';
  import { ReactComponent as LogoMobile_guerillaPorn } from '../../images/guerillaporn-nats-page-logo-with-text.svg';

  import { ReactComponent as LogoWeb_huntForMen } from '../../images/huntformen-logo.svg';
  import { ReactComponent as LogoMobile_huntForMen } from '../../images/huntformen-logo.svg';

  import { ReactComponent as LogoWeb_mrManTrailerTrashBoys } from '../../images/mrman-trailer-trash-boys-site-logo.svg';
  import { ReactComponent as LogoMobile_mrManTrailerTrashBoys } from '../../images/mrman-trailer-trash-boys-site-logo-mobile.svg';

  import { ReactComponent as LogoWeb_nakedSword } from '../../images/ns_logo_blue.svg';
  import { ReactComponent as LogoMobile_nakedSword } from '../../images/ns_logo_white.svg';

  import { ReactComponent as LogoWeb_mrMan } from '../../images/nakedsword-mrman-logo-black.svg';
  import { ReactComponent as LogoMobile_mrMan } from '../../images/nakedsword-mrman-logo-black.svg';

  import { ReactComponent as LogoWeb_tripleX } from '../../images/nakedswordxxx-black-logo.svg';
  import { ReactComponent as LogoMobile_tripleX } from '../../images/nakedswordxxx-white-logo.svg';

  import { ReactComponent as LogoWeb_peterFever } from '../../images/peterfever-logo.svg';
  import { ReactComponent as LogoMobile_peterFever } from '../../images/peterfever-logo-mobile.svg';

  import { ReactComponent as LogoWeb_qReel } from '../../images/qreel-logo.svg';
  import { ReactComponent as LogoMobile_qReel } from '../../images/qreel-logo.svg';

  import { ReactComponent as LogoWeb_timVideovault } from '../../images/timvideovault-thunder.svg';
  import { ReactComponent as LogoMobile_timVideovault } from '../../images/timvideovault-thunder.svg';

  import { ReactComponent as LogoWeb_trailerTrashBoys } from '../../images/trailer-trash-boys-site-logo.svg';
  import { ReactComponent as LogoMobile_trailerTrashBoys } from '../../images/trailer-trash-boys-site-logo-mobile.svg';

  import { ReactComponent as LogoWeb_trentonDucati } from '../../images/trenton_ducati_com.svg';
  import { ReactComponent as LogoMobile_trentonDucati } from '../../images/trenton_ducati_com.svg';

  // Centralized logo mapping
  export const logoMapping = {
    [PROPERTY_ID.guerillaPorn]: {
      LogoWeb: LogoWeb_guerillaPorn,
      LogoMobile: LogoMobile_guerillaPorn,
    },
    [PROPERTY_ID.huntForMen]: {
      LogoWeb: LogoWeb_huntForMen,
      LogoMobile: LogoMobile_huntForMen,
    },
    [PROPERTY_ID.mrManTrailerTrashBoys]: {
      LogoWeb: LogoWeb_mrManTrailerTrashBoys,
      LogoMobile: LogoMobile_mrManTrailerTrashBoys,
    },
    [PROPERTY_ID.nakedSword]: {
      LogoWeb: LogoWeb_nakedSword,
      LogoMobile: LogoMobile_nakedSword,
    },
    [PROPERTY_ID.mrMan]: {
      LogoWeb: LogoWeb_mrMan,
      LogoMobile: LogoMobile_mrMan,
    },
    [PROPERTY_ID.tripleX]: {
      LogoWeb: LogoWeb_tripleX,
      LogoMobile: LogoMobile_tripleX,
    },
    [PROPERTY_ID.peterFever]: {
      LogoWeb: LogoWeb_peterFever,
      LogoMobile: LogoMobile_peterFever,
    },
    [PROPERTY_ID.qReel]: {
      LogoWeb: LogoWeb_qReel,
      LogoMobile: LogoMobile_qReel,
    },
    [PROPERTY_ID.timVideovault]: {
      LogoWeb: LogoWeb_timVideovault,
      LogoMobile: LogoMobile_timVideovault,
    },
    [PROPERTY_ID.trailerTrashBoys]: {
      LogoWeb: LogoWeb_trailerTrashBoys,
      LogoMobile: LogoMobile_trailerTrashBoys,
    },
    [PROPERTY_ID.trentonDucati]: {
      LogoWeb: LogoWeb_trentonDucati,
      LogoMobile: LogoMobile_trentonDucati,
    },
  };