import React, { createContext, useState, useEffect } from 'react';
import { fetchNatsCode, decodedNatsCodeData } from '../services/nats-service/nats.service.js';

export const NatsContext = createContext();

export const NatsProvider = ({ children }) => {
	const [natsCode, setNatsCode] = useState(null);
	const [decodedNatsCode, setDecodedNatsCode] = useState(null);

	// Fetch and set NATS code on mount
	useEffect(() => {
		const initializeNats = async () => {
			console.log('Initializing NATS code...');
			const code = await fetchNatsCode();

			if (code) {
				setNatsCode(code);
				const decodedCode = decodedNatsCodeData(code);
				setDecodedNatsCode(decodedCode);
				console.log('Decoded NATS code:', decodedCode);
			} else {
				console.warn('NATS code is unavailable or invalid.');
			}
		};

		initializeNats();
	}, []);

	return (
		<NatsContext.Provider value={{ natsCode, decodedNatsCode }}>
			{children}
		</NatsContext.Provider>
	);
};